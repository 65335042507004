import React, { useState, useEffect } from "react"

export default function Reports() {
  const [widget, setWidget] = useState()
  useEffect(() => {
    // get data from GitHub api

    fetch(
      "https://widget.datablocks.se/api/rose/widgets/archive?token=ba9e5384-519e-44a9-8e64-3a6b10c9c98c&c=19741a9c-8331-4579-8168-85dc73044280"
    )
      .then(function (response) {
        // The API call was successful!
        return response.text()
      })
      .then(function (html) {
        // This is the HTML from our response as a text string
        console.log(html)
        setWidget(html)
      })

      .catch(function (err) {
        // There was an error
        console.warn("Something went wrong.", err)
      })
  }, [])

  const result = widget

  console.log(result)

  return (
    <div>
      <div className="mfn-widget">
        <div className="max-w-3xl mx-auto text-base">
          <div dangerouslySetInnerHTML={{ __html: result }} />
        </div>
      </div>
    </div>
  )
}
