import React from "react"
import Layout from "../../components/layout"
import Footer from "../../components/Footer"
import Seo from "../../components/seo"
import Reports from "../../components/Reports"
import PageHeader from "../../components/PageHeader"

export default function FinancialInformationPage() {
  const pageTitle = "Financial Information"
  return (
    <Layout isInvestor pageTitle={pageTitle}>
      <Seo title={pageTitle} />
      <div className=" text-center py-8 md:py-16">
        <PageHeader text="Financial Reports" />
        <Reports />
      </div>
      <div className="pt-24">
        <Footer />
      </div>
    </Layout>
  )
}
